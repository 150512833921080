import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';
import logo from '../assets/img/logo.png';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const WIDGET_API_STAGE_URL = 'https://backend-stage.estate.hart-digital.com';

const createWidget = async () => {
  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get("id");
  const env = searchParams.get("env");

  const widgetApiHandler = new ApiStore(env === 'stage' ? WIDGET_API_STAGE_URL : WIDGET_API_URL)

  const planData = await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: env === 'stage' ? WIDGET_API_STAGE_URL : WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama'],
    locale: 'ru',
    logo: logo,
    dictionaryOverrides: {
      'made-by-text': '',
      'made-by-link': '',
    },
    colors: {
    main: '#0748A2',
    mainText: '#ffffff'
  },
  }

  new Widget('#widget', options);
}

createWidget();
